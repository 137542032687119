import axios from 'axios'
import { Toast } from 'vant'
import { baseUrl } from "@/request/url_config";

// 配置axios示例
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000
})

// 添加请求拦截器
instance.interceptors.request.use((config) => {
  // 发送请求之前
  return config
}, (error) => {
  // 请求错误
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use((res) => {
  // 响应数据处理
  const { data } = res
  if (data.code === 200) {
    return data.data
  } else {
    Toast.fail(data.message || '系统异常')
    return Promise.reject(data.message)
  }
}, (error) => {
  Toast.fail('网络异常')
  // 响应错误
  return Promise.reject(error)
})

export default instance
