import request from '../index'

/**
 * 获取合同url
 */
export function getContract(params) {
  return request({
    url: '/rest/student/dossier/signupInfo',
    method: 'GET',
    params,
    headers: {
      'signKey': params.signKey
    }
  })
}

/**
 * 获取签名合同url
 */
export function getSignContract(params) {
  return request({
    url: '/student/contract/getSignContract',
    method: 'GET',
    params,
    headers: {
      'signKey': params.signKey,
      'xy-token': params.token,
    }
  })
}

/**
 * 上传合同
 */
export function signupContract(data) {
  return request({
    url: '/rest/student/signup/signupContract',
    method: 'POST',
    data,
    headers: {
      'signKey': data.signKey
    }
  })
}

/**
 * 上传文件
 */
export function uploadFile(data) {
  return request({
    url: '/api/student/file/upload',
    method: 'POST',
    data
  })
}
